<template>
  <page-content>
    <template v-slot:main>
      <!-- Hey WELCOME -->
      <div v-ripple class="block__content welcome">
        <!-- Welcome box -->
        <div class="d-block">
          <h1 class="welcome__text">
            {{ $t("home.welcome") }}, <b>{{ userData.fullName }}</b>
          </h1>
          <span
            class="text-h5 take__nice__day"
            v-html="$t('home.haveaniceday')"
          >
          </span>
        </div>
        <!-- Spinner here -->
        <div class="spinner__area">
          <img :src="welcome.icon" />
        </div>
      </div>
      <div class="no_toolbar">
        <!-- report number -->
        <common-amount-report />
        <!-- upcoming episode -->
        <upcoming-events />
        <!-- latest-episodes -->
        <latest-episodes />
      </div>
    </template>
    <template v-slot:side>
      <notification ref="Notification" v-if="isWebVersion && show == 1" />
      <detail-profile
        ref="DetailProfile"
        v-if="isWebVersion && show == 2"
        @cancel="closeProfile"
        @success="closeProfile"
      />
    </template>
  </page-content>
</template>

<script>
import CommonAmountReport from "@/components/home/AmountReport";
import UpcomingEvents from "@/components/home/UpcomingEvents";
import Notification from "@/components/home/Notification";
import LatestEpisodes from "@/components/home/LatestEpisodes.vue";
import PageContent from "@/components/PageContent";
import DetailProfile from "@/components/users/DetailProfile.vue";

export default {
  components: {
    PageContent,
    Notification,
    CommonAmountReport,
    UpcomingEvents,
    LatestEpisodes,
    DetailProfile,
  },
  computed: {
    userData() {
      return this.$store.getters["Authen/getUser"] || [];
    },
  },
  data() {
    return {
      show: this.$route.query.profile ? 2 : 1,
      user: {},
      welcome: {
        message: "",
        icon: "icons/trophy.png",
      },
    };
  },
  mounted() {
    this.renderWelcome();
    this.$bus.on("openNotificationViewBooking", ({ id }) =>
      this.viewBooking(id)
    );
    this.$bus.on("openNotificationViewEpisode", ({ id }) =>
      this.viewEpisode(id)
    );
    this.$bus.on("openProfileDetail", () => (this.show = 2));
  },
  methods: {
    async renderWelcome() {
      this.welcome.message = "Have a nice day at work !";
    },
    viewBooking(id) {
      if (this.$refs.Notification) {
        this.$refs.Notification.openBooking(id);
      }
    },
    viewEpisode(id) {
      if (this.$refs.Notification) {
        this.$refs.Notification.openEpisode(id);
      }
    },
    closeProfile() {
      if (this.isWebVersion) {
        this.$router.push("home");
        this.show = 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./home.scss";
</style>
